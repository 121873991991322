import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { useRef, useState } from 'react';
import { Skeleton } from '@sendible/design-system/src';
import { Container, MediaSlot, MotionButton, Slider } from './index.styles';
import { ComposeMediaCard } from './components/ComposeMediaCard';
import { useGetMediasForComposeBox } from '../../models/medias';
import { composeMediaInteractions } from '../../composeMediaInteractions';

export const MediaAttached = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const attachedMediasOnActiveTab = useGetMediasForComposeBox();

  /**
   * SLIDER AND PAGINATION CONTROLS
   */
  const gapBetweenMediaCards = 16;
  const mediaCardWidth = 104;
  const offset = (ref.current?.offsetParent as HTMLElement)?.offsetWidth || 680;
  const totalMediaSpace = mediaCardWidth + gapBetweenMediaCards;
  const mediasPerPage = offset / totalMediaSpace;
  const pages = attachedMediasOnActiveTab?.length ? attachedMediasOnActiveTab.length - mediasPerPage : 0;

  const handlePrevSliderButton = () => {
    setPage((prev) => {
      if (prev === 0) return prev;

      return prev - 1;
    });
  };

  const handleNextSliderButton = () => {
    setPage((prev) => {
      if (prev >= pages) return prev;

      return prev + 1;
    });
  };

  const handleDropdownClick = (mediaID: number, option: string) => {
    if (option === 'remove') {
      composeMediaInteractions.removeMedia(mediaID);
    }
  };

  return createPortal(
    <Container>
      <>
        <AnimatePresence>
          {page > 0 ? (
            <MotionButton
              key="prev-button"
              className="attach-prev nav-button"
              icon="arrow_left"
              onClick={handlePrevSliderButton}
              size={16}
              initial={{ x: '-5rem' }}
              animate={{ x: 0 }}
              exit={{ x: '-5rem' }}
            />
          ) : null}
          {page < pages ? (
            <MotionButton
              key="next-button"
              className="attach-next nav-button"
              icon="arrow_right"
              onClick={handleNextSliderButton}
              size={16}
              initial={{ x: '5rem' }}
              animate={{ x: 0 }}
              exit={{ x: '5rem' }}
            />
          ) : null}
        </AnimatePresence>
        <Slider
          animate={ref.current ? { x: 0 * page - page * totalMediaSpace } : {}}
          transition={{ ease: 'easeInOut' }}
          ref={ref}
        >
          {attachedMediasOnActiveTab?.map((media, index) => (
            <MediaSlot key={`${media.id}-${index}`}>
              {media.status != 'Ready' ? (
                <Skeleton
                  key={`loading_${index}`}
                  shape="square"
                  width="6.5rem"
                />
              ) : (
                <ComposeMediaCard
                  id={media.id}
                  ariaMediaCardLabel="compose-media-label"
                  orderNumber={attachedMediasOnActiveTab?.length > 1 ? `${Number(index) + 1}` : ''}
                  tabIndex={0}
                  testId="asset-video"
                  thumbUrl={media.thumbnailUrl}
                  mediaType={media.type}
                  isGif={media.metadata && media.metadata?.mediaType?.includes('gif')}
                  dropdownButtonClick={(option: string) => {
                    handleDropdownClick(media.id, option);
                  }}
                  videoDuration={media.metadata?.duration}
                  open={(id: string | number) => {
                    // eslint-disable-next-line no-console
                    console.log(id);
                  }}
                />
              )}
            </MediaSlot>
          ))}
        </Slider>
      </>
    </Container>,
    document.getElementById('mediaAttached') as HTMLElement
  );
};
