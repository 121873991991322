import styled, { css } from 'styled-components';

export const Container = styled.li.attrs<UploadingFileProgressBarProps>(({ uploadProgress = 100, status }) => ({
  style: {
    backgroundSize: status === 'Uploading' ? `${uploadProgress}% 100%` : '0% 0%',
  },
}))<UploadingFileProgressBarProps>`
  ${({ status = 'Uploading', theme: { colors } }) => {
    const backgroundOptions = {
      ['Aborted' as string]: 'white',
      ['Ready' as string]: colors.semanticSuccessTint90,
      ['Errored' as string]: colors.semanticDangerTint90,
      ['Uploading' as string]: 'transparent',
      ['Uploaded' as string]: colors.brandPrimaryTint90,
      ['Processing' as string]: colors.brandPrimaryTint90,
    } as const;

    return css`
      display: flex;
      box-sizing: border-box;
      flex-flow: row wrap;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      min-height: 3rem;
      background-color: ${backgroundOptions[status]};
      background-image: linear-gradient(${colors.brandPrimaryTint90}, ${colors.brandPrimaryTint90});
      background-repeat: no-repeat;
      width: 100%;
    `;
  }}
`;

export const ContentBar = styled.div`
  gap: 1rem;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  align-items: center;
  display: flex;

  &:hover {
    .spinner-icon {
      display: none;
    }
    .cancel-button {
      display: block;
    }
  }
  .cancel-button {
    display: none;
  }
`;

export const FileInfo = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  overflow: hidden;
  gap: 1rem;
  .cancelled-text {
    flex-shrink: 0;
  }
`;

export const NameContainer = styled.div`
  overflow: hidden;
  flex: 1;
  p {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }
  p:not(.sub-message) {
    white-space: nowrap;
  }
  .text {
    width: 100%;
  }
`;

export const StatusIcon = styled.div`
  display: flex;
  align-items: center;
`;
