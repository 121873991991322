import sharedStateBridge, {
  composeTabs,
  COMPOSE_TABS,
  defaultCompose,
  ComposeStore,
  MediaAttachedType,
  MediaIdType,
} from '@sendible/shared-state-bridge';
import { publish, EVENTS } from '@sendible/event-manager';

const clearComposeBoxState = () => {
  publish(EVENTS.CANCEL_ALL_UPLOADS, {});

  const sharedState = sharedStateBridge.getSharedState();

  sharedStateBridge.setItem({
    ...sharedState,
    compose: { ...defaultCompose },
  });
};

const setActiveComposeBoxTab = (tabId: composeTabs | undefined) => sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', tabId);

const setIsComposeBoxOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', state);

const setIsMediaAttacherOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', state);

const toggleMediaAttacher = () => {
  const currentMediaAttacherState = sharedStateBridge.getSharedState().compose.isMediaAttacherOpen || false;

  sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', !currentMediaAttacherState);
};

const updateNonCustomisedTabs = () => {
  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;

  if (composeState?.tabsState && composeState.tabsState[COMPOSE_TABS.MESSAGE]?.mediaAttached) {
    const originalTabMedias = composeState.tabsState[COMPOSE_TABS.MESSAGE]?.mediaAttached;
    const newTabsState = { ...composeState.tabsState };

    Object.entries(composeState.tabsState)
      .filter(([tabName, tabsState]) => tabName !== COMPOSE_TABS.MESSAGE && !tabsState.isCustomised)
      .forEach(([tabName, tabsState]) => {
        newTabsState[tabName as composeTabs] = {
          ...tabsState,
          mediaAttached: originalTabMedias,
        };
      });
    sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);
  }
};
const getAttachedMedia = (): MediaAttachedType | undefined => {
  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;
  const { activeComposeBoxTab, tabsState } = composeState;

  return tabsState && tabsState[activeComposeBoxTab]?.mediaAttached;
};

const attachMedia = (mediaId: number) => {
  setIsMediaAttacherOpen(false);

  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;

  if (composeState?.tabsState) {
    const { tabsState, activeComposeBoxTab } = composeState;
    const newTabsState = {
      ...tabsState,
      [activeComposeBoxTab]: {
        ...tabsState[activeComposeBoxTab],
        isCustomised: activeComposeBoxTab !== COMPOSE_TABS.MESSAGE ? true : tabsState[activeComposeBoxTab]?.isCustomised,
        mediaAttached: { ...tabsState[activeComposeBoxTab]?.mediaAttached, [mediaId]: {} as MediaType },
      },
    };

    sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);
    if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) {
      updateNonCustomisedTabs();
    }
  }
};

const updateMediaProperties = (mediaId: number, mediaItem: MediaType) => {
  setIsMediaAttacherOpen(false);

  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;

  if (composeState?.tabsState) {
    const { tabsState, activeComposeBoxTab } = composeState;
    const newTabsState = {
      ...tabsState,
      [activeComposeBoxTab]: {
        ...tabsState[activeComposeBoxTab],
        mediaAttached: { ...tabsState[activeComposeBoxTab]?.mediaAttached, [mediaId]: mediaItem },
      },
    };

    sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);
    if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) updateNonCustomisedTabs();
  }
};

const setMajorErrorFeedback = (message: string) => {
  publish(EVENTS.ERROR_MESSAGE_FEEDBACK, {
    message,
  });
};

const getAllAttachedMediaIds = (): MediaIdType[] => {
  const compose: ComposeStore = sharedStateBridge.getSharedState()?.compose;
  const { tabsState } = compose;

  const setOfIds = Object.values(tabsState).reduce((acc, tabState) => {
    if (tabState.mediaAttached) {
      Object.keys(tabState.mediaAttached).forEach((mediaId) => {
        acc.add(mediaId);
      });
    }

    return acc;
  }, new Set<MediaIdType>());

  return Array.from(setOfIds);
};

const canSaveMessage = () => sharedStateBridge.getSharedState()?.compose?.canSaveMessage;

const setTabsState = (tabInfo: { [key: string]: { isCustomised?: boolean } }) => {
  const tabsState = sharedStateBridge.getSharedState()?.compose?.tabsState;

  const newTabsState = { ...tabsState };

  Object.keys(newTabsState).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(tabInfo, key)) {
      delete newTabsState[key as composeTabs];
    }
  });

  Object.entries(tabInfo).forEach(([tabName, tabInfoState]) => {
    // for each tab present in compose box
    newTabsState[tabName as composeTabs] = {
      ...tabsState[tabName], // whatever media we had previously attached
      ...tabInfoState, // whatever state comes from compose box
    };
  });

  sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);

  updateNonCustomisedTabs();
};

const setCanSaveMessage = (state: boolean) => {
  sharedStateBridge.updateSpecificKey('compose', 'canSaveMessage', state);
  publish(EVENTS.TRIGGER_PUBLISHING_VALIDATION_CHANGES, {});
};

const removeMedia = (mediaIdToRemove: number) => {
  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;

  if (composeState?.tabsState) {
    const { tabsState, activeComposeBoxTab } = composeState;
    const newTabsState = {
      ...tabsState,
      [activeComposeBoxTab]: {
        ...tabsState[activeComposeBoxTab],
        isCustomised: activeComposeBoxTab !== COMPOSE_TABS.MESSAGE ? true : tabsState[activeComposeBoxTab]?.isCustomised,
        mediaAttached: {
          ...tabsState[activeComposeBoxTab]?.mediaAttached,
          [mediaIdToRemove]: undefined,
        },
      },
    };

    sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);

    if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) {
      updateNonCustomisedTabs();
    }
  }
};

const removeAllMedia = () => {
  const composeState: ComposeStore = sharedStateBridge.getSharedState()?.compose;

  if (composeState?.tabsState) {
    const { tabsState, activeComposeBoxTab } = composeState;
    const newTabsState = {
      ...tabsState,
      [activeComposeBoxTab]: {
        ...tabsState[activeComposeBoxTab],
        mediaAttached: {},
      },
    };

    sharedStateBridge.updateSpecificKey('compose', 'tabsState', newTabsState);
    if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) updateNonCustomisedTabs();
  }
};

export const composeMediaInteractions = {
  updateMediaProperties,
  attachMedia,
  canSaveMessage,
  clearComposeBoxState,
  getAttachedMedia,
  getAllAttachedMediaIds,
  removeAllMedia,
  removeMedia,
  setActiveComposeBoxTab,
  setCanSaveMessage,
  setIsComposeBoxOpen,
  setIsMediaAttacherOpen,
  setMajorErrorFeedback,
  setTabsState,
  toggleMediaAttacher,
};
