import { useTranslation } from 'react-i18next';
import { Icon, Text, Spinner, Button, TextProps } from '@sendible/design-system/src';
import { useBackgroundUploaderContext } from '@sendible/frontendv2/src/components/BackgroundUploader/context';
import { ContentBar, FileInfo, Container, NameContainer, StatusIcon } from './index.styles';

export const UploadingFile = ({ id, uploadProgress, status, mediaType, name, errorMessage }: UploadingFileProps) => {
  const { t } = useTranslation('common_terms');
  const { cancelUpload, canCancelUpload } = useBackgroundUploaderContext();

  // eslint-disable-next-line no-nested-ternary
  const icon = mediaType === 'Image' ? 'image' : mediaType === 'Video' ? 'video' : 'file';

  return (
    <Container
      key={id}
      status={status}
      uploadProgress={uploadProgress}
      data-testid={`uploading-${name}`}
    >
      <ContentBar>
        <Icon
          className="type-icon"
          color={status === 'Uploaded' ? 'brandDark' : 'brandDarkAlpha70'}
          name={icon}
        />
        <FileInfo>
          <NameContainer>
            <Text
              align="left"
              color={(status === 'Uploaded' || status === 'UploadFailed' ? 'brandDark' : 'brandDarkAlpha70') as TextProps['color']}
              maxLines={1}
              variation="body_14"
              title={name.length > 27 ? name : ''}
            >
              {name}
            </Text>
            {status === 'UploadFailed' && errorMessage && (
              <Text
                className="sub-message"
                align="left"
                color={'semanticDangerShade20' as TextProps['color']}
                maxLines={1}
                variation="body_12"
                testId={`upload-error-${name}`}
                title={errorMessage.length > 27 ? errorMessage : ''}
              >
                {t(errorMessage)}
              </Text>
            )}
          </NameContainer>
          {status !== 'Ready' && (
            <StatusIcon>
              <Spinner
                testId={`upload-spinner-${name}`}
                className="spinner-icon"
                size="xs"
              />
              {canCancelUpload(id) && (
                <Button
                  icon="close"
                  data-testid={`cancel-${name}`}
                  className="cancel-button"
                  onClick={() => cancelUpload(id)}
                />
              )}
            </StatusIcon>
          )}
          {status === 'Ready' && (
            <StatusIcon>
              <Icon
                data-testid={`uploader-success-${name}`}
                className="status-icon"
                color="semanticSuccess"
                name="check"
              />
            </StatusIcon>
          )}

          {/* Uncomment and fix those once we treat the UploadFailed messages
          {status === 'Errored' && errorMessage && (
            <StatusIcon>
              <Icon
                data-testid="uploader-error"
                className="status-icon"
                color="semanticDanger"
                name="danger"
              />
            </StatusIcon>
          )}
          {status === 'Aborted' && (
            <Text
              align="left"
              color="brandDarkAlpha70"
              maxLines={1}
              variation="body_12"
              className="cancelled-text"
              testId={`upload-cancelled-${name}`}
            >
              {t('cancelled')}
            </Text>
          )} */}
        </FileInfo>
      </ContentBar>
    </Container>
  );
};
