import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { MediaIdType } from '@sendible/shared-state-bridge';
import { MediaFileType } from '../../models/medias/types';
import queryClient from '../../data-layer/queryClient';
import endpoints from '../../data-layer/endpoints';
import { BackgroundUploader } from '.';

type BackgroundUploaderContextType = {
  addAbortAPIToFile: (fileId: string, abortAPI: () => void) => void;
  addFile: (file: File, abortController: AbortController, source: SourceType, libraryName?: string) => string;
  canBeClosed: boolean;
  canCancelAll: boolean;
  canCancelUpload: (fileId: string) => boolean;
  cancelAllUploads: () => void;
  cancelUploadsByMediaId: (mediaIds: MediaIdType[]) => void;
  cancelUpload: (fileId: string) => void;
  closeUploader: () => void;
  isClosed: boolean;
  isMinimised: boolean;
  itemCountByStatus: Record<string, number>;
  setIsMinimised: Dispatch<SetStateAction<boolean>>;
  setUploadingIdsFromMediaLibrary: Dispatch<SetStateAction<string[]>>;
  updateFileError: (params: UpdateFileErrorParamsType) => void;
  updateFileProgress: (params: UpdateFileProgressParamsType) => void;
  updateFileReady: (fileId: string, mediaId: number) => void;
  uploadingFiles: Record<string, UploadingFileType>;
  uploadingIdsFromMediaLibrary: string[];
  updateFileMediaId: (params: { fileId: string; mediaId: number }) => void;
};

export const BackgroundUploaderContext = createContext({} as BackgroundUploaderContextType);

export const BackgroundUploaderProvider = ({ children }: Component) => {
  const [uploadingFiles, setUploadingFiles] = useState<Record<string, UploadingFileType>>({});
  const [uploadingIdsFromMediaLibrary, setUploadingIdsFromMediaLibrary] = useState<string[]>([]);
  const [isMinimised, setIsMinimised] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const itemCountByStatus = useMemo(
    () =>
      Object.values(uploadingFiles).reduce(
        (acc, uploadingFile) => {
          const { status } = uploadingFile;

          return {
            ...acc,
            [status]: acc[status] + 1,
          };
        },
        { Uploading: 0, Uploaded: 0, Ready: 0, Processing: 0, Deleted: 0, New: 0, UploadFailed: 0 }
      ),
    [uploadingFiles]
  );

  const canBeClosed = useMemo(() => Object.values(uploadingFiles).every(({ status }) => status !== 'Uploading'), [uploadingFiles]);
  const canCancelAll = useMemo(() => !!itemCountByStatus.Uploading, [itemCountByStatus.Uploading]);

  const closeUploader = () => {
    setIsClosed(true);
    setUploadingFiles({});
    setUploadingIdsFromMediaLibrary([]);
  };

  const addFile = (file: File, abortController: AbortController, source: SourceType, libraryName?: string) => {
    const fileId = Math.random().toString();
    let type: MediaFileType | undefined;

    if (file.type.includes('image')) {
      type = MediaFileType.Image;
    } else if (file.type.includes('video')) {
      type = MediaFileType.Video;
    }

    setIsMinimised(false);
    setIsClosed(false);
    setUploadingFiles((prev) => {
      return {
        ...prev,
        [fileId]: {
          id: fileId,
          name: file.name,
          mediaType: type,
          status: 'Uploading',
          libraryName,
          uploadProgress: 1,
          mediaSize: file.size,
          abortController,
          source,
        },
      };
    });

    return fileId;
  };

  const updateFileProgress = ({ fileId, uploadProgress, abortController, mediaId }: UpdateFileProgressParamsType) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            mediaId,
            id: fileId,
            uploadProgress,
            abortUpload:
              abortController &&
              (() => {
                abortController.abort();
              }),
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileError = ({ fileId, errorMessage }: UpdateFileErrorParamsType) => {
    setIsMinimised(false);
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            errorMessage,
            status: 'UploadFailed',
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileReady = (fileId: string, mediaId: number) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            id: fileId,
            mediaId,
            status: 'Uploaded',
          },
        };
      }

      return { ...items };
    });
  };
  const updateFileMediaId = ({ fileId, mediaId }: { fileId: string; mediaId: number }) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            mediaId,
          },
        };
      }

      return { ...items };
    });
  };
  const addAbortAPIToFile = (fileId: string, abortAPI: () => void) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            abortAPI,
          },
        };
      }

      return { ...items };
    });
  };
  const cancelAllUploads = () => {
    if (canCancelAll) {
      Object.values(uploadingFiles).forEach((uploadingFile) => {
        const { status, abortUpload, abortAPI, abortController } = uploadingFile;

        if (status === 'Uploading') {
          abortController.abort();

          if (abortUpload) {
            abortUpload();
          }
          if (abortAPI) {
            abortAPI();
          }

          setUploadingFiles((items) => {
            if (items[uploadingFile.id]) {
              return {
                ...items,
                [uploadingFile.id]: { ...uploadingFile, status: 'UploadFailed' },
              };
            }

            return { ...items };
          });
        }
      });
    }
  };

  const cancelUpload = (fileId: string) => {
    if (uploadingFiles[fileId]) {
      const { abortUpload, abortAPI, abortController } = uploadingFiles[fileId];

      abortController.abort();

      queryClient.invalidateQueries([endpoints.GetMedia.endpoint]);

      if (abortUpload && abortAPI) {
        abortUpload();
        abortAPI();
      }
      setUploadingFiles((items) => {
        if (items[fileId]) {
          return {
            ...items,
            [fileId]: {
              ...(items[fileId] || {}),
              status: 'UploadFailed',
            },
          };
        }

        return { ...items };
      });
    }
  };

  const canCancelUpload = (fileId: string) => !!uploadingFiles[fileId].abortController;
  const cancelUploadsByMediaId = (mediaIds: MediaIdType[]) => {
    Object.values(uploadingFiles).forEach((file) => file.mediaId && mediaIds.includes(file.mediaId.toString()) && cancelUpload(file.id));
  };

  useEffect(() => {
    if (canBeClosed) {
      setIsMinimised(false);
    }
  }, [canBeClosed]);

  return (
    <BackgroundUploaderContext.Provider
      value={{
        addAbortAPIToFile,
        addFile,
        canBeClosed,
        canCancelAll,
        canCancelUpload,
        cancelAllUploads,
        cancelUpload,
        cancelUploadsByMediaId,
        closeUploader,
        isClosed,
        isMinimised,
        itemCountByStatus,
        setIsMinimised,
        setUploadingIdsFromMediaLibrary,
        updateFileProgress,
        updateFileError,
        updateFileReady,
        uploadingFiles,
        uploadingIdsFromMediaLibrary,
        updateFileMediaId,
      }}
    >
      {!isClosed && createPortal(<BackgroundUploader />, document.getElementById('root') as HTMLElement)}
      {children}
    </BackgroundUploaderContext.Provider>
  );
};

export const useBackgroundUploaderContext = (): BackgroundUploaderContextType => useContext(BackgroundUploaderContext);
