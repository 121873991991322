import SharedStateBridge, { defaultCompose } from '@sendible/shared-state-bridge';

export default () => {
  const newUserData = window.loginContext.getCurrentDetails();
  const oldUserSharedState = SharedStateBridge.getSharedState();

  SharedStateBridge.setItem({
    compose: defaultCompose,
    user: {
      ...oldUserSharedState.user,
      userId: newUserData.id,
      username: newUserData.login,
      email: newUserData.email,
      timezone: newUserData.timezone,
      isSwitchedUser: !window.loginContext.isLoggedInUserActive(),
    },
    app: {
      isCreativeEditorOpen: {
        url: null,
        serviceSet: null,
      },
      addThreadsPromptOpenMode: null,
      addTikTokPromptOpenMode: null,
      addingServiceId: null,
      blueskyConnectionModal: {
        isConnectOpen: false,
        reconnectionAccountId: null,
      },
    },
  });
};
