import { Icon, Modal, Popover } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const ModalComponent = styled(Modal)`
  font-size: 14px; // required to reset fontsize accross sendible and reconnections apps
`;

export const Heading = styled.div`
  align-items: center;
  display: flex;

  > img {
    color: #0085ff;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;

  .input {
    flex: 1;

    input {
      margin: 0;
    }
  }
`;

export const PopoverComponent = styled(Popover)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

export const PopoverContent = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: flex-start;
    color: ${colors.brandDarkTint30};

    h6 {
      color: ${colors.brandDark};
      font-size: 0.857rem;
      margin: 0 0 0.429rem 0;
      padding: 0;
    }

    a {
      color: ${colors.brandPrimary};
      font-weight: 500;
    }

    ol li {
      margin-bottom: 0.286rem;

      &::marker {
        font-weight: 500;
      }

      span {
        color: ${colors.brandDark};
        display: block;
        font-weight: 500;
      }
    }

    hr {
      margin: 0.571rem 0 0.571rem 0;
    }
  `}
`;

export const PopoverIcon = styled(Icon)`
  cursor: pointer;
`;

export const ReconnectName = styled.div`
  font-size: 1.25rem;
  padding: 0.5em 0 2.188rem 0;
`;
