import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useEffect } from 'react';
import { loadTranslations } from './i18n';
import { AddProfileModalPrompt } from './components';
import { ComposeBoxProvider } from './composeBoxContext';
import BlueskyConnection from './components/BlueskyConnection';
import { useUrlWithCredentials } from './data-layer/useUrlWithCredentials';
import { MediaSystemComponents } from './components/MediaSystemComponents';

export const FrontendV2Components = () => {
  const [sharedState] = useBridgeContext();

  useEffect(() => {
    loadTranslations(sharedState?.user?.language);
  }, [sharedState?.user?.language]);

  const app = sharedState?.app;

  const isComposeBoxOpen = sharedState?.compose?.isComposeBoxOpen;
  const addThreadsPromptOpenMode = app?.addThreadsPromptOpenMode;
  const addTikTokPromptOpenMode = app?.addTikTokPromptOpenMode;
  const blueskyConnectionModalIsConnect = app?.blueskyConnectionModal.isConnectOpen;
  const blueskyConnectionReconnectionAccountId = app?.blueskyConnectionModal.reconnectionAccountId;

  const getUrlWithCredentials = useUrlWithCredentials();

  return (
    <>
      {addThreadsPromptOpenMode && (
        <AddProfileModalPrompt
          serviceType="threads"
          mode={addThreadsPromptOpenMode}
        />
      )}
      {addTikTokPromptOpenMode && (
        <AddProfileModalPrompt
          serviceType="tiktok"
          mode={addTikTokPromptOpenMode}
        />
      )}
      {window?.js_featureTagsManager?.isFeatureTag('mediaSystem') && isComposeBoxOpen ? (
        <ComposeBoxProvider>
          <MediaSystemComponents />
        </ComposeBoxProvider>
      ) : null}
      {blueskyConnectionModalIsConnect ? <BlueskyConnection getUrlWithCredentials={getUrlWithCredentials} /> : null}
      {blueskyConnectionReconnectionAccountId ? (
        <BlueskyConnection
          getUrlWithCredentials={getUrlWithCredentials}
          accountId={blueskyConnectionReconnectionAccountId}
        />
      ) : null}
    </>
  );
};
