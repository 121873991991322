import { fetchWithHeaders } from '@sendible/common';
import { Box, Button, Input, Spinner, Text, useToastNotificationContext } from '@sendible/design-system/src';
import sharedStateBridge from '@sendible/shared-state-bridge';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { ModalComponent, Heading, InputContainer, PopoverComponent, PopoverContent, PopoverIcon, ReconnectName } from './index.styles';
import { ProfilesUrls } from '../../constants/urls';

type BlueskyReconnectionProps = {
  accountId?: number | null;
  closeCallback?: () => void | null;
  getUrlWithCredentials: (url: string) => string;
  successCallback?: () => void | null;
};

const BlueskyConnection = ({ accountId = null, closeCallback, getUrlWithCredentials, successCallback }: BlueskyReconnectionProps) => {
  const isReconnection = !!accountId;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(isReconnection);
  const [isSaving, setIsSaving] = useState(false);
  const { showToastNotification } = useToastNotificationContext();

  const { t } = useTranslation('bluesky');

  useEffect(() => {
    if (isReconnection) {
      const url = `${getUrlWithCredentials(ProfilesUrls.account)}&account_id=${accountId}`;

      const getAccount = async () => {
        const { result } = await fetchWithHeaders({
          method: 'GET',
          url,
        });

        if (result?.username) {
          setUsername(result.username);
        }

        setIsLoading(false);
      };

      getAccount();
    }
  }, []);

  const showErrorMessage = () => {
    const notificationText = isReconnection
      ? `${t('cant_reconnect_your_account')} (${username}). ${t('try_again')}.`
      : `${t('cant_connect_your_account')} (${username}). ${t('try_again')}.`;

    showToastNotification({
      hideTimeout: 10,
      notificationText,
      type: NotificationTypeEnum.Error,
      showCloseButton: true,
    });
  };

  const showSuccessMessage = () => {
    const notificationText = isReconnection
      ? `${t('account_connected')} (${username}) ${t('has_been_reconnected')}.`
      : `${t('account_connected')} (${username}) ${t('has_been_connected')}.`;

    showToastNotification({
      hideTimeout: 10,
      notificationText,
      type: NotificationTypeEnum.Success,
      showCloseButton: true,
    });
  };

  const handleConnect = async () => {
    const sanitisedUsername = username.replace(/@/g, '');

    setIsSaving(true);

    try {
      const url = `${getUrlWithCredentials(ProfilesUrls.blueskyAuthenticate)}&username=${sanitisedUsername}&password=${password}`;

      const { result } = await fetchWithHeaders({
        method: 'POST',
        url,
        headers: { 'Content-Type': 'application/json' },
      });

      if (result?.status && result.status === 'Succeeded') {
        showSuccessMessage();
        sharedStateBridge.updateSpecificKey('app', 'blueskyConnectionModal', {
          isConnectOpen: false,
          reconnectionAccountId: null,
        });

        if (successCallback) {
          successCallback();
        }
      } else {
        showErrorMessage();
      }
    } catch (err) {
      showErrorMessage();
    } finally {
      setIsSaving(false);
    }
  };

  const isFormValid = username.trim() !== '' && password.trim() !== '' && password.length > 18;

  return (
    <ModalComponent
      variation="medium"
      headingTitle={
        <Heading>
          <img
            src="/s3_assets/icons/flat/v2/composebox/bluesky.svg"
            width="24"
            height="24"
            alt="Bluesky logo"
          />{' '}
          {isReconnection ? t('bluesky_reconnect') : t('bluesky_connect')}
        </Heading>
      }
      close={() => {
        sharedStateBridge.updateSpecificKey('app', 'blueskyConnectionModal', {
          isConnectOpen: false,
          reconnectionAccountId: null,
        });

        if (closeCallback) {
          closeCallback();
        }
      }}
    >
      {isLoading ? (
        <Spinner size="lg" />
      ) : (
        <Box
          orientation="column"
          alignSelf="flex-start"
          fullWidth
        >
          <Text>{t('bluesky_username')}</Text>
          {!isReconnection ? (
            <InputContainer>
              <Input
                ariaLabel={t('bluesky_username') as string}
                change={(value) => setUsername(value)}
                label={t('bluesky_username_placeholder') as string}
                value={username}
              />
              <PopoverComponent
                useClick
                placement="right-start"
                maxWidth="15rem"
                content={
                  <PopoverContent>
                    <h6>{t('where_is_this')}</h6>
                    <ol>
                      <li>
                        <span>
                          {t('open_bluesky_app')}{' '}
                          <a
                            href="https://bsky.app/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('bluesky_app')}
                          </a>{' '}
                          {t('and_login')}
                        </span>
                        {t('navigate_to_profile')}
                      </li>
                      <li>
                        <span>{t('find_username')}</span>
                        {t('tap_profile_picture_instruction')}
                      </li>
                    </ol>
                  </PopoverContent>
                }
              >
                <PopoverIcon
                  name="help"
                  color="brandDark"
                />
              </PopoverComponent>
            </InputContainer>
          ) : (
            <ReconnectName>{`@${username.replace('@', '')}`}</ReconnectName>
          )}
          <Text>{t('enter_bluesky_password')}</Text>
          <InputContainer>
            <Input
              ariaLabel={t('bluesky_password') as string}
              className="bluesky-input"
              change={(value) => {
                const cleaned = value.replace(/[^a-zA-Z0-9]/g, '');
                const limited = cleaned.slice(0, 16);
                const formatted = limited.replace(/(.{4})/g, '$1-');

                setPassword(formatted.replace(/-$/, ''));
              }}
              maxLength={19}
              label="____-____-____-____"
              value={password}
            />
            <PopoverComponent
              useClick
              placement="right-start"
              maxWidth="18rem"
              content={
                <PopoverContent>
                  <ol>
                    <li>
                      <span>
                        {t('go_to')}{' '}
                        <a
                          href="https://bsky.app/settings/app-passwords"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('bluesky_settings_path')}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>{t('generate_new_password')}</span>
                      {t('add_app_password_instruction')}
                    </li>
                    <li>
                      <span>{t('paste_in_field')}</span>
                      {t('use_generated_password')}
                    </li>
                  </ol>
                  <hr />
                  <p>
                    {t('app_passwords_management')}{' '}
                    <a
                      href="https://bsky.app/settings/app-passwords"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('bluesky_settings')}
                    </a>
                    .
                  </p>
                </PopoverContent>
              }
            >
              <PopoverIcon
                name="help"
                color="brandDark"
              />
            </PopoverComponent>
          </InputContainer>
          <Box
            contentJustify="space-between"
            alignSelf="center"
            contentAlignment="center"
            fullWidth
          >
            <Text>{t('pds_not_supported')}</Text>
            <Button
              appearance="primary"
              disabled={!isFormValid || isSaving}
              isLoading={isSaving}
              label={t('connect') as string}
              onClick={handleConnect}
              size={14}
              variation="fill"
            />
          </Box>
        </Box>
      )}
    </ModalComponent>
  );
};

export default BlueskyConnection;
