import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Body, ContainerBox } from './index.styles';
import { useBackgroundUploaderContext } from './context';
import { Header, UploadingFile } from './components';

export const BackgroundUploader = () => {
  const { uploadingFiles, isMinimised } = useBackgroundUploaderContext();
  const uploadingInfoRows = useMemo(
    () =>
      Object.values(uploadingFiles)
        .reverse()
        .map((media) => {
          return (
            <UploadingFile
              key={media.id}
              {...media}
            />
          );
        }),
    [uploadingFiles]
  );

  return createPortal(
    <ContainerBox data-testid="BackgroundUploader_test">
      <Header />
      {!isMinimised && (
        <Body hasSubHeader={false}>
          <ul>{uploadingInfoRows}</ul>
        </Body>
      )}
    </ContainerBox>,
    document.getElementById('root') as HTMLElement
  );
};
